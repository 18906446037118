import React, { useEffect, useState } from 'react'
import loadable from '@loadable/component'
import { useSelector, useDispatch } from 'react-redux'
import * as styles from './Table.module.css'
import TrueIcon from '../../assets/image/svg/trueIcon.inline.svg'

import { useTranslation } from 'react-i18next'
import Link, { navigate } from '../Link'
import * as exchDataActions from '../../store/actions/exchDataActions'
import { createTheme } from '@mui/material/styles'
import { useStaticQuery, graphql } from 'gatsby'
import { Title, Text } from '../../components/Core'
import { Spinner, Button } from 'react-bootstrap'
import styled from 'styled-components'
import theme from '../../utils/theme'
import { color, background } from 'styled-system'
import WithTheme from '../../helpers/WithTheme'
import makeStyles from '@mui/styles/makeStyles'

const MUIDataTable = loadable(() => import('mui-datatables'))
const TableCell = loadable(() => import('@mui/material/TableCell'))
const TableRow = loadable(() => import('@mui/material/TableRow'))

function createData(
  exchLogo,
  exchName,
  exchCode,
  charting,
  balance,
  spot,
  futures,
  whitelisting,
  slug,
  exchType
) {
  return {
    exchLogo,
    exchName,
    exchCode,
    charting,
    balance,
    spot,
    futures,
    whitelisting,
    slug,
    exchType,
  }
}

const Selector = styled.div`
  background-color: ${props => (props.userDarkMode ? '#191B20' : '#E0E0E0')};
  border-color: 'transparent';
  border-radius: 28px;
  padding: 4px;
  display: inline-flex;
  > button {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    padding-top: 8px !important;
    padding-bottom: 8px !important;
    padding-left: 24px !important;
    padding-right: 24px !important;
    border-radius: 22px;
    cursor: pointer;
    transition: 0.3s;
    &:hover {
      color: ${props =>
        props.userDarkMode
          ? theme.colors.primaryLight
          : theme.colors.primary} !important;
    }
    &:focus {
      box-shadow: 0 0 0 0.2rem
        ${props =>
          props.userDarkMode
            ? theme.colors.primaryLight
            : theme.colors.primary} !important;
    }
    ${color};
  }
  ${background};
`

const useStyles = makeStyles(theme => ({
  rightHeader: {
    '& span': {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'right',
      //   backgroundColor:'red',
    },
    '& div': {
      textAlign: 'right',
    },
  },
  centerHeader: {
    '& span': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      //   backgroundColor:'red',
    },
    '& div': {
      textAlign: 'center',
    },
  },
}))

const SupportedExchsTable = () => {
  const { t, ready } = useTranslation(['tables'], { useSuspense: false })
  const exchData = useSelector(state => state.exchData.exchData)
  const dispatch = useDispatch()
  const { i18n } = useTranslation()
  const userSettings = useSelector(state => state.user.user_settings)
  const userSessionExpired = useSelector(state => state.user.session_expired)
  const userDarkModeSelector = useSelector(state => state.user.darkMode)
  const userDarkMode = userSessionExpired
    ? userDarkModeSelector
    : typeof userSettings !== 'undefined'
    ? userSettings.dark_mode
    : false
  // const tableRows = useSelector(state => state.user.tableRows)
  const metaData = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            languages
          }
        }
      }
    `
  )
  const langs = metaData.site.siteMetadata.languages
  let i18nLang
  let lang

  if (typeof i18n.language !== 'undefined') {
    i18nLang = i18n.language.includes('-')
      ? i18n.language.split('-')[0]
      : i18n.language
  }

  lang =
    typeof langs.find(lang => lang === i18nLang) !== 'undefined'
      ? langs.find(lang => lang === i18nLang)
      : 'en'

  useEffect(() => {
    dispatch(exchDataActions.fetchExchData())
    // const interval = setInterval(() => {
    //   dispatch(exchDataActions.fetchExchData())
    // }, 90000)
    // return () => clearInterval(interval)
  }, [dispatch])

  //   useEffect(() => {
  //     dispatch(currenciesActions.fetchBtcExchRate(userCurr))
  //   }, [dispatch, userCurr])

  const [completed, setCompleted] = useState(false)

  useEffect(() => {
    const timer = setTimeout(() => {
      setCompleted(true)
    }, 3000)
    return () => clearTimeout(timer)
  }, [])

  const classes = useStyles()

  const columns = [
    {
      name: 'exchLogo',
      label: ready && `${t('logo')}`,
      options: {
        // filter: true,
        filterList: [],
        sort: false,
        setCellProps: () => ({
          style: {
            paddingRight: 0,
            background: userDarkMode ? 'black' : 'rgb(248, 249, 250)',
          },
        }),
        customHeadRender: ({ index, ...column }) => {
          return ready && (
            <TableCell aria-label={t('logo')} key={index}></TableCell>
          )
        },
      },
    },
    {
      name: 'exchName',
      label: ready && `${t('name')}`,
      options: {
        // filter: true,
        filterList: [],
        sort: true,
        setCellProps: () => ({
          style: {
            fontWeight: 700,
            fontSize: 16,
            position: 'sticky',
            left: '0',
            background: userDarkMode ? 'black' : 'rgb(248, 249, 250)',
            zIndex: 100,
          },
        }),
        setCellHeaderProps: () => ({
          titlearia: 'Sort by Exchange Name',
          style: {
            whiteSpace: "nowrap",
            position: "sticky",
            left: 0,
            background: userDarkMode ? 'black' : 'rgb(248, 249, 250)',
            zIndex: 101
          }
        }),
      },
    },
    {
      name: 'exchCode',
      label: ready && `${t('code')}`,
      options: {
        // filter: true,
        filterList: [],
        sort: true,
        setCellProps: () => ({
          style: {
            opacity: 0.6,
          },
        }),
      },
    },
    {
      name: 'charting',
      label: ready && `${t('charting')}`,
      options: {
        // filter: true,
        filterList: [],
        sort: false,
        setCellHeaderProps: () => ({
          className: classes.centerHeader,
        }),
      },
    },
    {
      name: 'balance',
      label: ready && `${t('balanceTracking')}`,
      options: {
        // filter: true,
        filterList: [],
        sort: false,
        setCellHeaderProps: () => ({
          className: classes.centerHeader,
        }),
        // setCellProps: () => ({
        //   style: {
        //     width: '1rem',
        //   },
        // }),
      },
    },
    {
      name: 'spot',
      label: ready && `${t('spotTrading')}`,
      options: {
        // filter: true,
        filterList: [],
        sort: false,
        setCellHeaderProps: () => ({
          className: classes.centerHeader,
        }),
      },
    },
    {
      name: 'futures',
      label: ready && `${t('futuresTrading')}`,
      options: {
        // filter: true,
        filterList: [],
        sort: false,
        setCellHeaderProps: () => ({
          className: classes.centerHeader,
        }),
      },
    },
    {
      name: 'whitelisting',
      label: ready && `${t('hasWhitelisting')}`,
      options: {
        // filter: true,
        filterList: [],
        sort: false,
        setCellHeaderProps: () => ({
          className: classes.centerHeader,
        }),
      },
    },
    {
      name: 'slug',
      label: '',
      options: {
        // filter: true,
        filterList: [],
        sort: false,
        display: false,
        viewColumns: false,
      },
    },
    {
      name: 'exchType',
      label: 'Exchange Type',
      options: {
        // filter: true,
        filterList: [],
        sort: false,
        display: false,
        viewColumns: false,
      },
    },
  ]

  const [cols, setCols] = useState(columns)
  const [selectedRows] = useState([])

  const options = {
    filter: false,
    download: true,
    print: false,
    rowsPerPageOptions: [10, 20, 50],
    textLabels: {
      body: {
        noMatch: completed ? (
          t('noMatchExchanges')
        ) : (
          <div style={{ alignItems: 'center', minHeight: '200px' }}>
            <Spinner
              variant={userDarkMode ? 'light' : 'dark'}
              animation="border"
            />
          </div>
        ),
        toolTip: t('sort'),
        columnHeaderTooltip: column => `${t('sortBy')} ${column.label}`,
      },
      toolbar: {
        search: t('search'),
        downloadCsv: t('downloadCSV'),
        print: t('print'),
        viewColumns: t('viewColumns'),
        filterTable: t('filter'),
      },
      pagination: {
        next: t('nextPage'),
        previous: t('previousPage'),
        rowsPerPage: t('rowsPerPage'),
        displayRows: t('of'), // 1-10 of 30
      },
      viewColumns: {
        title: t('showColumns'),
        titlearia: t('showColumnsAria'),
      },
    },

    // filterType: 'checkbox',
    responsive: 'standard',
    selectableRowsHeader: false,
    // selectableRows: 'multiple',
    selectableRowsHideCheckboxes: true,
    customRowRender: data => {
      // console.table(data);
      // console.log(dataIndex % 2);
      // if (dataIndex % 2 === 0) return null;
      // let marketNameLowCase = data[10].toLowerCase();
      let exchSlug = data[7]
      const handleRowClick = () => navigate(`/${lang}/exchanges/${exchSlug}/`)

      return (
        <TableRow onClick={handleRowClick} hover={true} key={data[2]}>
          <TableCell>
            <Link aria-label={exchSlug} to={`/${lang}/exchanges/${exchSlug}/`}>
              {data[0]}
            </Link>
          </TableCell>
          <TableCell
            style={{
              position: 'sticky',
              left: '0',
              zIndex: 100,
              background: userDarkMode ? 'black' : 'rgb(248, 249, 250)',
              color: userDarkMode ? '#FAFAFA' : '#191B20',
              fontWeight: 700,
              fontSize: 16,
            }}
          >
            {data[1]}
          </TableCell>
          <TableCell
            style={{
              color: userDarkMode ? '#828282' : 'rgba(0, 0, 0, 0.56)',
            }}
          >
            {data[2]}
          </TableCell>
          <TableCell
            style={{
              textAlign: 'center',
              color: userDarkMode ? '#828282' : 'rgba(0, 0, 0, 0.56)',
            }}
          >
            {data[3]}
          </TableCell>
          <TableCell
            style={{
              width: 140,
              textAlign: 'center',
              color: userDarkMode ? '#3B96FF' : '#3377C6',
            }}
          >
            {data[4]}
          </TableCell>
          <TableCell
            style={{
              width: 140,
              textAlign: 'center',
              color: userDarkMode ? '#FAFAFA' : '#191B20',
              fontVariant: 'tabular-nums',
            }}
          >
            {data[5]}
          </TableCell>
          <TableCell
            style={{
              width: 140,
              textAlign: 'center',
              color: userDarkMode ? '#FAFAFA' : '#191B20',
              fontVariant: 'tabular-nums',
            }}
          >
            {data[6]}
          </TableCell>
          <TableCell
            style={{
              width: 140,
              textAlign: 'center',
              color: userDarkMode ? '#FAFAFA' : '#191B20',
              fontVariant: 'tabular-nums',
            }}
          >
            {data[7]}
          </TableCell>

          {/* <TableCell 
            style={{ 
              width: 110,
              color: userDarkMode ? '#FAFAFA' : '#191B20',
            }}
          >
            {data[7]}
          </TableCell>
          <TableCell 
            style={{
              color: userDarkMode ? '#FAFAFA' : '#191B20',
            }}
          >
            {data[8]}
          </TableCell> */}
        </TableRow>
      )
    },
    rowsPerPage: 50,
  }

  const [selectedFilter, setSelectedFilter] = useState(null)

  const onFilter = value => {
    const filteredCols = [...cols]
    let filterList = []
    setSelectedFilter(value)
    if (value === 'All') {
      setCols(columns)
    }
    if (value === selectedRows) {
      filteredCols[2].options.filterList = selectedRows
      setCols(filteredCols)
    }

    if ((value !== 'All') & (value !== selectedRows)) {
      filterList.push(value)
      filteredCols[8].options.filterList = filterList
      setCols(filteredCols)
    }
  }

  let data = []
  exchData
    ? exchData.map(function (item) {
        let balanceEnabled = item.exchBalanceEnabledV2 ? (
          <TrueIcon
            width="16"
            height="16"
            alt={t('balanceTracking')}
            title={t('balanceTracking')}
          />
        ) : null
        let chartingEnabled = item.exchTradeEnabledV2 ? (
          <TrueIcon
            width="16"
            height="16"
            alt={t('charting')}
            title={t('charting')}
          />
        ) : null
        let spotEnabled = item.hasSpotTrading ? (
          <TrueIcon
            width="16"
            height="16"
            alt={t('spotTrading')}
            title={t('spotTrading')}
          />
        ) : null
        let futuresEnabled = item.hasFuturesTrading ? (
          <TrueIcon
            width="16"
            height="16"
            alt={t('futuresTrading')}
            title={t('futuresTrading')}
          />
        ) : null
        let ipWhitelisting = item.exchHasWhitelisting ? (
          <TrueIcon
            width="16"
            height="16"
            alt={t('whitelisting')}
            title={t('whitelisting')}
          />
        ) : null

        // const exchLogo = <img width="16" height="16"  className={styles.exchangeImg} src={ process.env.WWW_URL + `/assets/img/exchange/${item.exchCode}-icon.webP`} alt={`${item.exchName} Logo`} />;
        let exchLogo = (
          <img
            width="20"
            height="20"
            className={styles.exchangeImg}
            src={
              process.env.WWW_URL +
              `/assets/img/exchange/${item.exchCode}-icon.webP`
            }
            alt={`${item.exchName} Logo`}
            onError={e => {
              e.target.onerror = null
              e.target.src =
                process.env.WWW_URL + `/assets/img/currency/empty.webP`
            }}
          />
        )

        let exchType =
          item.exchCode === 'UNI2' ||
          item.exchCode === 'DYDX' ||
          item.exchCode === 'SSHI'
            ? 'defi'
            : item.exchCode === 'DERI' ||
              item.exchCode === 'BIFU' ||
              item.exchCode === 'BMEX' ||
              item.exchCode === 'FTXI'
            ? 'derivatives'
            : 'spot'

        return data.push(
          createData(
            exchLogo,
            item.exchName,
            item.exchCode,
            chartingEnabled,
            balanceEnabled,
            spotEnabled,
            futuresEnabled,
            ipWhitelisting,
            item.slug,
            exchType
          )
        )
      })
    : (data = [])
  return ready && (
    <>
      <MUIDataTable
        title={
          <div>
            <Selector
              background={userDarkMode ? '#191B20' : '#E0E0E0'}
            >
              <Button
                style={{
                  borderColor: 'transparent',
                  backgroundColor:
                    userDarkMode === true && selectedFilter === 'All'
                      ? '#2B2F36'
                      : userDarkMode === false && selectedFilter === 'All'
                      ? '#FFFFFF'
                      : 'transparent',
                  boxShadow: 'transparent',
                  color:
                    userDarkMode === true && selectedFilter === 'All'
                      ? '#FFFFFF'
                      : userDarkMode === false && selectedFilter === 'All'
                      ? '#000000'
                      : userDarkMode === true
                      ? '#9A9B9D'
                      : '#636363',
                }}
                tabIndex={0}
                onClick={() => onFilter('All')}
              >
                {t('all')}
              </Button>
              <Button
                style={{
                  borderColor: 'transparent',
                  backgroundColor:
                    userDarkMode === true && selectedFilter === 'spot'
                      ? '#2B2F36'
                      : userDarkMode === false && selectedFilter === 'spot'
                      ? '#FFFFFF'
                      : 'transparent',
                  boxShadow: 'transparent',
                  color:
                    userDarkMode === true && selectedFilter === 'spot'
                      ? '#FFFFFF'
                      : userDarkMode === false && selectedFilter === 'spot'
                      ? '#000000'
                      : userDarkMode === true
                      ? '#9A9B9D'
                      : '#636363',
                }}
                tabIndex={0}
                onClick={() => onFilter('spot')}
              >
                {t('spot')}
              </Button>
              <Button
                style={{
                  borderColor: 'transparent',
                  backgroundColor:
                    userDarkMode === true && selectedFilter === 'derivatives'
                      ? '#2B2F36'
                      : userDarkMode === false &&
                        selectedFilter === 'derivatives'
                      ? '#FFFFFF'
                      : 'transparent',
                  boxShadow: 'transparent',
                  color:
                    userDarkMode === true && selectedFilter === 'derivatives'
                      ? '#FFFFFF'
                      : userDarkMode === false &&
                        selectedFilter === 'derivatives'
                      ? '#000000'
                      : userDarkMode === true
                      ? '#9A9B9D'
                      : '#636363',
                }}
                tabIndex={0}
                onClick={() => onFilter('derivatives')}
              >
                {t('derivatives')}
              </Button>
              <Button
                style={{
                  borderColor: 'transparent',
                  backgroundColor:
                    userDarkMode === true && selectedFilter === 'defi'
                      ? '#2B2F36'
                      : userDarkMode === false && selectedFilter === 'defi'
                      ? '#FFFFFF'
                      : 'transparent',
                  boxShadow: 'transparent',
                  color:
                    userDarkMode === true && selectedFilter === 'defi'
                      ? '#FFFFFF'
                      : userDarkMode === false && selectedFilter === 'defi'
                      ? '#000000'
                      : userDarkMode === true
                      ? '#9A9B9D'
                      : '#636363',
                }}
                tabIndex={0}
                onClick={() => onFilter('defi')}
              >
                DeFi
              </Button>
            </Selector>
          </div>
        }
        data={data}
        columns={cols}
        options={options}
      />
    </>
  )
}

const muiTheme = ({ userDarkMode }) =>
  createTheme({
    components: {
      MuiPaper: {
        styleOverrides: {
          root: {
            color: userDarkMode ? '#FAFAFA' : '#191B20',
            backgroundColor: userDarkMode ? '#191B20' : '#FAFAFA',
          },
        },
      },
      MuiInput: {
        styleOverrides: {
          root: {
            color: userDarkMode ? '#FAFAFA' : '#191B20',
            fontFamily: 'CircularStd, sans-serif',
          },
          underline: {
            '&:before': { borderBottom: '2px solid transparent !important' },
            '&:after': { borderBottom: '2px solid #3377C6 !important' },
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {},
          input: {
            backgroundColor: userDarkMode
              ? 'rgba(255, 255, 255, 0.1)'
              : 'rgba(0, 0, 0, 0.1)',
            paddingTop: 14,
            paddingRight: 16,
            paddingBottom: 15,
            paddingLeft: 16,
            borderRadius: 5,
          },
        },
      },
      MuiSvgIcon: {
        styleOverrides: {
          root: {
            color: userDarkMode
              ? 'rgba(255, 255, 255, 0.56)'
              : 'rgba(0, 0, 0, 0.56)',
          },
        },
      },
      MUIDataTableSearch: {
        styleOverrides: {
          searchIcon: {
            color: userDarkMode
              ? 'rgba(255, 255, 255, 0.56)'
              : 'rgba(0, 0, 0, 0.56)',
          },
          searchText: { maxWidth: 400 },
        },
      },
      MUIDataTableViewCol: {
        styleOverrides: {
          label: {
            color: userDarkMode ? '#FAFAFA' : '#191B20',
          },
          title: {
            color: userDarkMode ? '#FAFAFA' : '#191B20',
          },
        },
      },
      MuiTableRow: {
        styleOverrides: {
          hover: {
            '&:hover': {
              backgroundColor: userDarkMode
                ? 'rgba(255, 255, 255, 0.1) !important'
                : 'rgba(0, 0, 0, 0.04) !important',
            },
          },
        },
      },
      MuiTypography: {
        styleOverrides: {
          body1: { fontFamily: 'CircularStd, sans-serif', margin: '20px 0' },
        },
      },
      MuiTablePagination: {
        styleOverrides: {
          caption: {
            fontFamily: 'CircularStd, sans-serif',
            fontSize: '14px',
            fontWeight: 500,
            lineHeight: '18px',
            letterSpacing: '0em',
            color: userDarkMode ? '#FAFAFA' : '#191B20',
            opacity: 0.6,
          },
          select: {
            fontFamily: 'CircularStd, sans-serif',
            fontSize: '14px',
            color: '#3377C6',
            '&:focus': { borderRadius: '10px' },
            backgroundColor: 'transparent',
          },
          selectLabel: {
            fontFamily: 'CircularStd, sans-serif',
            fontSize: '14px',
            color: userDarkMode ? '#FAFAFA' : '#191B20',
            marginBottom: '2px',
            opacity: 0.6
          },
          displayedRows: {
            fontFamily: 'CircularStd, sans-serif',
            fontSize: '14px',
            color: userDarkMode ? '#FAFAFA' : '#191B20',
            marginBottom: '2px',
            opacity: 0.6
          },
          selectIcon: { color: '#3377C6' },
          actions: {
            color: userDarkMode ? '#3377C6' : '#3377C6',
            backgroundColor: 'transparent',
          },
          root: { zIndex: 999 },
        },
      },
      MUIDataTable: {
        styleOverrides: {
          root: { fontFamily: 'CircularStd, sans-serif' },
          paper: {
            borderRadius: 0,
            overflow: 'hidden',
            boxShadow: 'none',
            backgroundColor: 'transparent',
          },
        },
      },
      MUIDataTableHeadCell: {
        styleOverrides: {
          root: {
            backgroundColor: 'transparent',
            fontFamily: 'CircularStd, sans-serif',
          },
          toolButton: {
            backgroundColor: 'transparent',
            fontFamily: 'CircularStd, sans-serif',
            marginRight: '-8px',
            fontWeight: 300,
          },
          fixedHeader: {
            backgroundColor: 'transparent',
            color: userDarkMode ? '#828282' : 'rgba(0, 0, 0, 0.56)',
            fontWeight: 300,
          },
          data: {
            // backgroundColor: userDarkMode ? '#191B20' : 'white' ,
            color: userDarkMode ? '#828282' : 'rgba(0, 0, 0, 0.56)',
          },
          sortActive: {
            color: userDarkMode ? '#828282' : 'rgba(0, 0, 0, 0.56)',
          },
          contentWrapper: {},
        },
      },
      MuiTableSortLabel: {
        styleOverrides: {
          icon: {
            color: userDarkMode
              ? 'rgba(255, 255, 255, 0.56) !important'
              : 'rgba(0, 0, 0, 0.56) !important',
            marginTop: '3px',
            marginRight: '0',
          },
        },
      },
      MuiSelected: {
        styleOverrides: {
          icon: {
            color: userDarkMode ? '#828282' : 'gray',
            '&:hover': {
              color: '#3377C6',
            },
          },
        },
      },
      MuiButton: {
        styleOverrides: { root: { backgroundColor: 'transparent' } },
      },
      MuiTableFooter: {
        styleOverrides: { root: { backgroundColor: 'transparent' } },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: { fontFamily: 'CircularStd, sans-serif', fontWeight: 300 },
        },
      },
      MUIDataTableToolbar: {
        styleOverrides: {
          root: {
            minHeight: '80px !important',
            paddingLeft: '0px',
            paddingRight: '0px',
            zIndex: 999,
          },
          icon: {
            color: userDarkMode ? '#828282' : 'gray',
            '&:hover': {
              color: '#3377C6',
            },
          },
          titleText: {
            color: userDarkMode ? '#3986F7' : '#185398',
            fontWeight: 900,
            fontFamily: 'CircularStd, sans-serif',
            fontSize: '24px !important',
            textTransform: 'uppercase',
            letterSpacing: '-0.02em',
          },
          actions: {
            display: 'flex',
            flex: 'initial',
            '& > span, & > button': { order: 99 },
            '& > span:last-child, & > button:last-child': { order: 1 },
            '& > span:nth-child(4), & > button:nth-child(4)': { order: 2 },
          },
        },
      },
      MUIDataTableSelectCell: {
        styleOverrides: { headerCell: { backgroundColor: 'transparent' } },
      },
      MuiTableCell: {
        styleOverrides: {
          head: { fontWeight: 300 },
          body: {
            fontSize: '14px',
            fontFamily: 'CircularStd, sans-serif',
            cursor: 'pointer',
            color: userDarkMode ? '#FAFAFA' : 'black',
          },
          root: {
            borderBottomColor: userDarkMode
              ? 'rgba(255, 255, 255, 0.1) !important'
              : 'light',
            fontFamily: 'CircularStd, sans-serif',
            color: userDarkMode ? '#FAFAFA' : '#191B20',
            fontWeight: 300,
          },
        },
      },
      MuiChip: { styleOverrides: { root: { display: 'none' } } },
    },
    mixins: {},
    palette: {
      text: { hint: 'rgba(0, 0, 0, 0.38)' },
      mode: 'light',
      type: 'light',
    },
  })

export default WithTheme(muiTheme)(SupportedExchsTable)
